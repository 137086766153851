<div class="banner-container">
  <div class="banners-swiper-container">
    <div class="banner-section">
      <picture class="banner-image-container">
        <source width="639" height="520" srcset='assets/img/banners/Mobile.webp'
          media="(max-width: 639.9px)" type="image/webp">
        <source width="639" height="520" srcset='assets/img/banners/Mobile.png'
          media="(max-width: 639.9px)" type="image/png">
        <source width="1024" height="500" srcset='assets/img/banners/Tablet.webp'
          media="(max-width: 1024.9px)" type="image/webp">
        <source width="1024" height="500" srcset='assets/img/banners/Tablet.png'
          media="(max-width: 1024.9px)" type="image/png">
        <source width="2000" height="500" srcset='assets/img/banners/Desktop.webp'
          media="(min-width: 1025px)" type="image/webp">
        <source width="2000" height="500" srcset='assets/img/banners/Desktop.png'
          media="(min-width: 1025px)" type="image/png">
        <img width="2000" height="500" src="assets/img/banners/Desktop.png"
          alt="Unlimited Talk, Text & Data for $25 " title="Unlimited Talk, Text & Data for $25" loading="eager">
      </picture>
      <div class="banner-content-container">
        <p class="special-text">This Holiday Season</p>
        <p class="caption-text">$25/mo <span class="for">for</span></p>
        <h1 class="header">UNLIMITED</h1>
        <h4 class="subheader">Talk, Text & Data</h4>
        <!-- <div class="price">
          <p class="amount"><span class="dollar">$</span>25</p>
          <p class="mo">/mo</p>
        </div> -->
        <button class="button primary" (click)="goToOfferLanding()" aria-label="Shop Today">Shop Today!</button>
      </div>
    </div>
  </div>
</div>